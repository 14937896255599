import React, { useEffect, useState } from 'react'
import { Card, Container, Row } from 'react-bootstrap';
import { ListTask } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import DashBoard_card from './DashBoard_card';
import { IoAddCircle } from 'react-icons/io5';

interface User_data{
  aadhar:string;
  address:string;
  department:string;
  district:string;
  email:string;
  emp_id:string;
  emp_type:string;
  id:string;
  mobile:string;
  name:string;
  pan:string;
  password:string;
  picture:string;
  state:string;
  }
const Team_dash = () => {
  const navigate= useNavigate()
  const [user_info,setUser_info]=useState<any>([]);
  const [wlcmmsg,setWlcmmsg]=useState<boolean>(false)
  const [msgview,setMsgview]=useState(false);

  const cardClickhandle=()=>{
    navigate('/enroll-in-course');
  }
  const registerInterns=()=>{
    navigate('/internship-form')
  }
  const registerClient=()=>{
    navigate('/client-application')
  }
  const registerColaborator=()=>{
    navigate('/colaborator-application')
  }
  useEffect(()=>{
    const checklogin = window.sessionStorage.getItem('loggedIn');
    
    if (checklogin==null ) {
        navigate('/login');
    } 
    const wlcomemsg=window.sessionStorage.getItem('welcomemsg');
    if (wlcomemsg==="yes") {
      setWlcmmsg(true); 
    }
    const data=window.sessionStorage.getItem('userinfo');
        const parseData = data !== null ? JSON.parse(data):null;
        setUser_info(parseData as User_data[]);
    setTimeout(() => {
      setWlcmmsg(false);
     // setMsgview(true);
     window.sessionStorage.setItem('welcomemsg','');
    }, 5000);
  },[])
 
  return (
    <div className='p-1 text-start fw-bold'>
      { wlcmmsg?<Container className='d-flex justify-content-start ms-5 '>
      Welcome, {user_info.name} 
      </Container>:null}
       {/* <Card className='d-none d-sm-block'>
          <ListTask color='black' size={50} />
       </Card> */}
       <div className=' d-flex align-items-center ms-3'>
       <h5>Register New</h5>
       </div>
       <div className='container' >
        <Row xs={2} xl={6}>
        <DashBoard_card name='Student' image='https:codingmaster.dev/career/api/learner_256.png' card_click={cardClickhandle}/>
        <DashBoard_card name='Internship' image='https:codingmaster.dev/career/api/learner_256.png' card_click={registerInterns}/>
        <DashBoard_card name='Client' image='https://images.unsplash.com/photo-1549923746-c502d488b3ea?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' card_click={registerClient}/>
        <DashBoard_card name='Colaborator' image='https://plus.unsplash.com/premium_photo-1658507004148-6346d0d9c404?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' card_click={registerColaborator}/>
        </Row>
        
       </div>
       <hr />
       <Container fluid>
       <h5 className='h5'>Data</h5>
       </Container>
    </div>

  )
}

export default Team_dash
