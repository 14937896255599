import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Digital = () => {
  const navigate = useNavigate();
  const goToServicesTop = () => {
    navigate("/services", { state: { scrollToTop: true } });
  };
  const goToContectTop = () => {
    navigate("/contect", { state: { scrollToTop: true } });
  };

  return (
    <div className="row d-flex flex-column justify-content-center align-content-center ">
      <div className="col-12 ">
        <h1 className="fs-1 fw-bold text-light text-center mb-4">
          Ready to digitally transform your business?
        </h1>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="text-light fs-5 text-center">
            We have helped various entrepreneurs, startups, and companies with
            our digital solutions in growing their business. It's your turn now!
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center gap-2">
          <button
            onClick={goToServicesTop}
            className="px-3 py-1 fs-4 fw-bold rounded-4 btn btn-success"
          >
            Services
          </button>

          <button
            onClick={goToContectTop}
            className="px-3 py-1 fs-4 fw-bold rounded-4 btn btn-success"
          >
            contect Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Digital;
