import React from 'react'
import { Card, Col,Image } from 'react-bootstrap'
import { CardImage, PersonAdd } from 'react-bootstrap-icons';
interface Card_data{
  name:string;
  image?:string
  card_click?:()=>void
}
const DashBoard_card = ({name,image,card_click}:Card_data) => {
  return (
    <Col className='mb-2'  onClick={card_click} >
      <Card className='p-1 shadow-sm card-dash' style={{borderColor:'rgb(10,250,111)'}}>
        <Card.Body>
          <Card.Title className='text-center text-primary' >
         <a className="link-offset-2 link-underline link-underline-opacity-0" href="#">{name}</a>
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default DashBoard_card
