import React from 'react'
import '../Footerstyle.module.css'
import { Image } from 'react-bootstrap'
import { FaFacebookSquare, FaYoutube } from 'react-icons/fa'
import { FaSquareInstagram, FaSquareXTwitter } from 'react-icons/fa6'

function Footer() {
    return (
        <footer className="footer p-2">
            <div className="footer-left col-md-4 col-sm-6 mt-3">
                <p className="about">
                    <span> About the company</span> Welcome to Coding Master, your premier destination for cutting-edge IT services and comprehensive online training. We specialize in delivering top-notch solutions in website development, mobile app development, and digital marketing, including impactful social media ads.
                </p>
                <div className='mt-2 ' >
                    <a href="#"><FaFacebookSquare
                        className="fs-2 mx-2  about-icon"
                        target='_blank'
                        style={{ color: "limegreen" }}
                    /></a>
                    <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaSquareInstagram
                            className="fs-2 mx-2  about-icon"
                            style={{ color: "limegreen" }}
                        />
                    </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <FaSquareXTwitter
                            className="fs-2 mx-2  about-icon"
                            style={{ color: "limegreen" }}
                        />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <FaYoutube
                            className="fs-2 mx-2 about-icon"
                            style={{ color: "limegreen" }}
                        />
                    </a>
                </div>
            </div>
            <div className="footer-center col-md-4 col-sm-6 p-0 m-0">
                <div>
                    <i className="fa fa-map-marker"></i>
                    <p><span> Coding Master khutehi 486001</span> Rewa, India</p>
                </div>
                <div>
                    <i className="fa fa-phone"></i>
                    <p> +91 9171959701</p>
                </div>
                <div>
                    <i className="fa fa-envelope"></i>
                    <p><a href="#">contact@codingmaster.dev</a></p>
                </div>
            </div>
            <div className="footer-right col-md-4 col-sm-6">
                <h3 className='text-white'><span> <Image height={80} src={`https://codingmaster.dev/career/api/logo_icon_codingmaster.png`} roundedCircle /></span>Coding Master</h3>
                <p className="menu">
                    <a href="#"> Home</a> |
                    <a href="#"> About</a> |
                    <a href="#"> Our Courses</a> |
                    <a href="#"> Training & Internships</a> |

                </p>
                <p className="name"> Coding Master &copy; 2023</p>
            </div>
        </footer>
    )
}

export default Footer
