import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Lottie from "lottie-react";
import Done from "../Donefile/Done.json";
import { useNavigate } from 'react-router-dom'

import { ClipLoader } from "react-spinners";

const Internship_form: React.FC = () => {
  const [fisrtn, setFirstn] = useState<string>("");
  const [lastn, setLastn] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [course, setCourse] = useState<string>("");
  const [project, setProject] = useState<string>("");
  const [experience, setExperience] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setcity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [file, setFile] = useState<File | undefined>();
  const [alert, setAlert] = useState<Boolean>(false);
  const [show, setShow] = useState(false);
  const [errmsg, setErrmsg] = useState(true);
  const [msg, setMsg] = useState<string>("");
  const [msgcolor, setMsgcolor] = useState<string>("");
  const [apply_for, setApply_for] = useState<string>("");
  const [education, setEducation] = useState<string>("");
  const [promocode, setPromocode] = useState<string>("DIS500");
  const [alt_Mobile, setAlt_Mobile] = useState<string>("");
  const [name_Edu_name, setName_Edu_name] = useState<string>("")
  const navigate = useNavigate();
  const [loggedUrl, setLoggedUrl] = useState<string>("https://codingmaster.dev/career/api/enroll_in_internship.php");

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement & {
      files: FileList;
    };
    setFile(target.files[0]);
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShow(true);
    const headersList = {
      Accept: "*",
    };
    if (typeof file === "undefined") return;

    const Enroll_data = apply_for;
    const Academic_course = course;
    const Fullname = fisrtn + lastn

    const bodyContent = new FormData();
    bodyContent.append("name", Fullname);
    bodyContent.append("email", email);
    bodyContent.append("mobile", mobile);
    bodyContent.append("course", course);
    bodyContent.append("address", address);
    bodyContent.append("al_mobile", alt_Mobile);
    bodyContent.append("city", city);
    bodyContent.append("state", state);
    bodyContent.append("enrolled_in", Enroll_data);
    bodyContent.append("education", education);
    bodyContent.append("name_edu_org", name_Edu_name);
    bodyContent.append("academic_course", Academic_course);
    bodyContent.append("promocode", promocode);
    bodyContent.append("resume", file);

    try {
      const req = await fetch(
        loggedUrl,
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );
      
      const data = await req.json();
      console.log(data);
      console.log(data, req.status);
      setMsg(data.message);
      setMsgcolor(data.type);
      if (req.status === 200) {
        setAlert(true);
        setShow(false);

        setTimeout(() => {
          navigate('/')
        }, 7000);
      } else {
        setErrmsg(false);
      }
    } catch (error) {
      console.log("Error:", error)
    }
  };
  const sstorageData = async () => {
    const loggedIn = await sessionStorage.getItem("loggedIn");
    if (loggedIn === "1") {
      const data = sessionStorage.getItem('Employee_id');
      const setloggedinurl = "https://codingmaster.dev/career/api/enroll_in_internship.php" + "?id=" + data;
      setLoggedUrl(setloggedinurl);
    }
  }
  useEffect(() => {
    sstorageData();
  }, [])
  return (
    <div>
      <Container className="mt-3 py-3">
        {alert ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center rounded "
            style={{ height: "70vh" }}
          >
            <Lottie animationData={Done} className="h-50 m-0 p-0" />
            <p className="h5 m-0 p-0">
              Your Application has been submitted, successfully
            </p>
            <p className="h6 m-0 p-0">
              Please wait we are redirecting to our home page.
            </p>
          </div>
        ) : (
          <>
            <h3 className="h4 text-center mb-2">
              Apply For Internship
            </h3>
            <Form onSubmit={submitHandler} >
              <div className=" mb-5 bg-body-tertiary">
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setFirstn(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setLastn(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Alternate Mobile</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      onChange={(e) => setAlt_Mobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  {/* Education  */}
                  <Form.Group
                    as={Col}
                    md="4"
                    // controlId="validationCustom01"
                    className="text-start"
                    controlId="formDropdown"
                  >
                    <Form.Label>Education</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Education..</option>
                      <option value="college">College</option>
                      <option value="institute">Institute</option>
                      <option value="Student">Student</option>
                      <option value="university">University</option>
                    </Form.Select>
                  </Form.Group>

                  {/* Education end */}

                  {/* <Form.Group as={Col} md="4" controlId="validationCustom01" className='text-start'>
                                        <Form.Label>Project/Github link</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => setProject(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Course</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Courses..</option>
                      <option value="BSC">BSC</option>
                      <option value="BCOM">B COM</option>
                      <option value="BA">BA</option>
                      <option value="BCaA">BCA</option>
                      <option value="Other">Other..</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Apply for</Form.Label>
                    <Form.Select onChange={(e) => setApply_for(e.target.value)}>
                      <option>Choose One...</option>
                      <option value="Marketing">
                        Marketing & sales Executive
                      </option>
                      <option value="Digital marketing">
                        Digital Marketing
                      </option>
                      <option value="Graphics/UI & UX design">
                        Graphics/UI & UX design
                      </option>
                      <option value="Website ">Website Developer</option>
                      <option value="Mobile">Mobile App developer</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>College/Institute/University</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setName_Edu_name(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setcity(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Select onChange={(e) => setState(e.target.value)}>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Dadra and Nagar Haveli">
                        Dadra and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Puducherry">Puducherry</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Promo Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={promocode}
                      placeholder=""
                      onChange={(e) => setPromocode(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Resume</Form.Label>
                    <Form.Control
                      required
                      type="file"
                      accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={onFileInputChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <div className="text-center">
                    <Button variant="primary" className="btn mb-3 fw-bold text-dark" type="submit" style={{ backgroundColor: 'rgb(10,250,111)', borderColor: 'rgb(9,228,102)' }}>
                      Submit
                    </Button>
                  </div>
                </Row>
              </div>
            </Form>
          </>
        )}
      </Container>
      <Modal show={show} backdrop={"static"} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>Processing your data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {errmsg ? (
            <>
              <ClipLoader
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
                size={40}
                color="#16348C"
              />
              <p className="h6">Please wait</p>
            </>
          ) : (
            <>
              <p className={`text-${msgcolor}`}>Message:{msg}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Internship_form;
