import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";

import Form1 from "./Components/Form1";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Login from "./Components/Login";
import Team_dash from "./Components/Team_dash";
import Enrollincourses from "./Components/Enrollincourses";
import Internship_form from "./Components/Internship_form";
import Progressbar from "./Components/Progressbarview";
import Job from "./Components/Job";
import Client_form from "./Components/Client_form";
import Colaborator_form from "./Components/Colaborator_form";
import Basic_Details from "./Components/Basic_Details";
import Services from "./Components/Services";
import Course from "./Components/Course";
import Contect_Us from "./Components/Contect_Us";

function App() {
  const [home, setHome] = useState("/career");
  return (
    <div>
      <Router>
        <NavBar />
        <Progressbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="hiring-form" element={<HiringForm />} /> */}
          <Route path="/form" element={<Form1 />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/team-dash" element={<Team_dash />} />
          <Route path="/enroll-in-course" element={<Enrollincourses />} />
          <Route path="/internship-form" element={<Internship_form />} />\
          <Route path="/job" element={<Job />} />
          <Route path="/client-application" element={<Client_form />} />
          <Route
            path="/colaborator-application"
            element={<Colaborator_form />}
          />
          {<Route path="basic" element={<Basic_Details />} />}
          <Route path="/services" element={<Services />} />
          <Route path="/courses" element={<Course />} />
          <Route path="/contact" element={<Contect_Us />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
