import React, { useState } from 'react'
import {ProgressBar} from 'react-bootstrap'
interface progress_data{
    progressview?:boolean;
}
const Progressbarview = ({progressview}:progress_data) => {
    const [progress, setProgress]=useState<boolean>(false);
  return (
    <div>
      {progress ? <ProgressBar animated={true} now={100} role='progress' className='w-100' style={{ height: '5px', marginTop: '1px' }} /> : null}
    </div>
  )
}

export default Progressbarview
