import React, { useCallback, useState } from 'react'
import Card from 'react-bootstrap/Card'
import * as Icon from 'react-bootstrap-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { CardBody, Collapse, ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { log } from 'console';
interface user_data {
  user_email: string;
  userpswd: string;
}

function Login() {
  const [progress, setProgress] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [tokenAlert, setTokenAlert] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [userpassword, setUserpassword] = useState<string>("");
  const [userinfo, setUserinfo] = useState(false);
  const [loginfailed, setLoginfailed] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleClick = async () => {

    
      setProgress(true);
    if (!isValidEmail(email)) {
      setEmailAlert(true);
      setProgress(false);
    } else {
      setEmailAlert(false);
    }
    if (userpassword == "") {
      setTokenAlert(true);
      setProgress(false);
    } else {
      setTokenAlert(false);
    }

    let headersList = {
      "Accept": "*/*"
    }
    const bodyContent = new FormData();
    bodyContent.append("email", email);
    bodyContent.append("password", userpassword);

    const request = await fetch("https://codingmaster.dev/career/api/team_login.php", {
      method: "POST",
      body: bodyContent,
      headers: headersList
    });
    const response = await request.json();
    console.log(response);
    console.log(response.error);

    if (request.ok) {
      console.log(request.ok);
    }
    if (response.error == '200') {
      setLoggedIn(true);
      setProgress(false);
      const userinfo = response.user;
      window.sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
      window.sessionStorage.setItem("loggedIn", '1');
      window.sessionStorage.setItem('welcomemsg','yes');
       navigate('/team-dash');
       window.location.reload();
    }

    if (response.error == '400') {
      setProgress(false);
      setUserinfo(true);
      setLoginfailed(response.message);
    }
    
    
  }
  return (
    <>
      {progress ? <ProgressBar animated={true} now={100} role='progress' className='w-100' style={{ height: '5px', marginTop: '1px' }} /> : null}
      <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '70vh' }}>

        <div className="container mx-5">
          <div className='shadow-sm card rounded p-2'>
            <CardBody className='text-start'>
              <div className='text-center'>
                <Icon.PersonFill size={75} color='rgb(10,250,111)' />
              </div>
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3 my-4"
              >
                <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
                {emailAlert ? <p className="text-start ms-2" style={{ color: 'red' }}>Please enter email</p> : null}
              </FloatingLabel>
              <FloatingLabel className='my-4' controlId="floatingPassword" label="Password" >
                <Form.Control type="text" placeholder="Password" onChange={(e) => setUserpassword(e.target.value)} />
                {tokenAlert ? <p className="text-start ms-2" style={{ color: 'red' }}>Please enter password</p> : null}
              </FloatingLabel>
              <Button className='my-3 w-100 text-dark fw-bold btn-Hover' size='lg' type='button' style={{ backgroundColor: 'rgb(10,250,111)', borderColor: 'rgb(10,250,111)' }} onClick={handleClick}>Login</Button>
              <div onClick={() => setOpen(!open)}><a href="#" className="text-reset me-1 text-uppercase">notes:</a>{"This login features is available only for Coding Master's team's/collaborator's/partner's"}
              </div>
            </CardBody>
          </div>
          {userinfo ? <p className='fs-6 fw-semibold text-center text-danger mt-2'>{loginfailed}</p> : null}
        </div>
      </div>
    </>
  )
}
export default Login


