import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Lottie from "lottie-react";
import Done from "../Donefile/Done.json";
import { useNavigate } from "react-router-dom";

import { ClipLoader } from "react-spinners";

const Basic_Details: React.FC = () => {
  const [bisinuss, setBusinessName] = useState<string>("");
  const [owner, setBusinessOwner] = useState<string>("");
  const [mobile, setOwnerMobile] = useState<string>("");
  const [email, setsOwnerEmail] = useState<string>("");
  const [address, setBusinessAddress] = useState<string>("");
  const [city, setcity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [alert, setAlert] = useState<Boolean>(false);
  const [show, setShow] = useState(false);
  const [errmsg, setErrmsg] = useState(true);
  const [msg, setMsg] = useState<string>("");
  const [msgcolor, setMsgcolor] = useState<string>("");
  const [businessArea, setBusinessArea] = useState<string>("");
  const [enquiryfor, setEnquiryFor] = useState<string>("");
  const [alt_Mobile, setAlt_Mobile] = useState<string>("");
  const navigate = useNavigate();
  const [loggedUrl, setLoggedUrl] = useState<string>("");

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShow(true);
    const headersList = {
      Accept: "*",
    };
    console.log(bisinuss);
    console.log(owner);
    console.log(mobile);
    console.log(email);
    console.log(address);
    console.log(city);
    console.log(state);
    console.log(alt_Mobile, businessArea, enquiryfor);
    const MobileNumber = mobile + "," + alt_Mobile;
    const bodyContent = new FormData();
    bodyContent.append("business_name", bisinuss);
    bodyContent.append("business_owner", owner);
    bodyContent.append("owner_email", email);
    bodyContent.append("onwer_mobile", MobileNumber);
    bodyContent.append("business_address", address);
    bodyContent.append("business_area", businessArea);
    bodyContent.append("enquiry_for", enquiryfor);
    bodyContent.append("city", city);
    bodyContent.append("state", state);

    try {
      const req = await fetch(loggedUrl, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await req.json();
      console.log(data);
      console.log(data, req.status);
      setMsg(data.message);
      setMsgcolor(data.type);
      if (req.status === 200) {
        setAlert(true);
        setShow(false);

        setTimeout(() => {
          navigate("/");
        }, 7000);
      } else {
        setErrmsg(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const sstorageData = async () => {
    const loggedIn = await sessionStorage.getItem("loggedIn");
    if (loggedIn === "1") {
      const data = sessionStorage.getItem("Employee_id");
      const setloggedinurl =
        "https://codingmaster.dev/career/api/enquiry.php" + "?id=" + data;
      setLoggedUrl(setloggedinurl);
    }
  };
  useEffect(() => {
    sstorageData();
  }, []);
  return (
    <div>
      <Container className="mt-3 py-3">
        {alert ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center rounded "
            style={{ height: "70vh" }}
          >
            <Lottie animationData={Done} className="h-50 m-0 p-0" />
            <p className="h5 m-0 p-0">
              Your Application has been submitted, successfully
            </p>
            <p className="h6 m-0 p-0">
              Please wait we are redirecting to our home page.
            </p>
          </div>
        ) : (
          <>
            <h3 className="h4 text-center mb-2">Enquiry From</h3>
            <Form onSubmit={submitHandler}>
              <div className=" mb-5 bg-body-tertiary">
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Owner</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setBusinessOwner(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      onChange={(e) => setOwnerMobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Alternate Mobile</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder=""
                      onChange={(e) => setAlt_Mobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      onChange={(e) => setsOwnerEmail(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    // controlId="validationCustom01"
                    className="text-start"
                    controlId="formDropdown"
                  >
                    <Form.Label>Business Area</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={businessArea}
                      onChange={(e) => setBusinessArea(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Select Area..</option>
                      <option value="Shop & Retail">Shop & Retail</option>
                      <option value="Education">Education</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Business Support & Supplies">
                        Business Support & Supplies
                      </option>
                      <option value="construction & Contractors">
                        construction & Contractors
                      </option>
                      <option value="Entertainment">Entertainment</option>
                      <option value="Food & Dining">Food & Dining</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Health & Medicine">
                        Health & Medicine
                      </option>
                      <option value="Gym">Gym</option>
                      <option value="Legal & Financial">
                        Legal & Financial
                      </option>
                      <option value="Agriculture">Home & Graden</option>
                      <option value="Manufacturing Wholesale Distribution">
                        Manufacturing Wholesale Distribution
                      </option>
                      <option value="Travel & Transportaion">
                        Travel & Transportaion
                      </option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md="8"
                    // controlId="validationCustom01"
                    className="text-start"
                    controlId="formDropdown"
                  >
                    <Form.Label>Enquiry For </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={enquiryfor}
                      onChange={(e) => setEnquiryFor(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Enquiry For..</option>
                      <option value="Digital Marketing">
                        {" "}
                        Digital Marketing
                      </option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="Mobile App Development">
                        Mobile App Development
                      </option>

                      <option value="other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  {/* Education  */}

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setBusinessAddress(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setcity(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Select onChange={(e) => setState(e.target.value)}>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Dadra and Nagar Haveli">
                        Dadra and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Puducherry">Puducherry</option>
                    </Form.Select>
                  </Form.Group>

                  {/* Education Business Support & Supplies

                  {/* <Form.Group as={Col} md="4" controlId="validationCustom01" className='text-start'>
                                        <Form.Label>Project/Github link</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => setProject(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                </Row>

                <Row className="mb-3">
                  <div className="text-center">
                    <Button
                      variant="primary"
                      className="btn mb-3 fw-bold text-dark"
                      type="submit"
                      style={{
                        backgroundColor: "rgb(10,250,111)",
                        borderColor: "rgb(9,228,102)",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Row>
              </div>
            </Form>
          </>
        )}
      </Container>
      <Modal show={show} backdrop={"static"} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>Processing your data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {errmsg ? (
            <>
              <ClipLoader
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
                size={40}
                color="#16348C"
              />
              <p className="h6">Please wait</p>
            </>
          ) : (
            <>
              <p className={`text-${msgcolor}`}>Message:{msg}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Basic_Details;
