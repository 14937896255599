import { Button, Card, Carousel, Col, Container, Image, Row } from "react-bootstrap";

import Lottie from "lottie-react";
import colaboration from "../Lottie_files/Colaboration_anim2.json";
import whyChooseus from '../Lottie_files/Animation - 1726593772619.json'
import { useNavigate } from "react-router-dom";
import img from "../images/pexels-mikael-blomkvist-6483582.jpg";
import style_custom from "../Custom.module.css";
import Digital from "./Digital";
import ExampleCarouselImage from "./ExampleCarouselImage";
import { Link } from "react-bootstrap-icons";
import servicesdata from '../data/services.json'
import { useState } from "react";
import { CgWebsite } from "react-icons/cg";
import { url } from "inspector";
import { MdOutlineDone } from 'react-icons/md'

interface serData_interface {
  id: number;
  title: string;
  url: string;
  des: string;
}

function Home() {
  const [serlist, setSerlist] = useState<serData_interface[]>(servicesdata.services);
  const [whylist,setWhylist]=useState<serData_interface[]>(servicesdata.whychooseus)
  const navigate = useNavigate();
  const enroll_Course = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
    navigate("/enroll-in-course");
  };
  const enroll_in_Internship = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
    navigate("/internship-form");
  };

  // Css Properties
  return (
    <>

      <div className='d-block d-sm-none w-100 p-0' style={{ backgroundColor: "rgb(235,254,243)" }} >
        <Lottie animationData={colaboration} loop={true} />
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <figure className="text-center ms-3">
              <blockquote className="blockquote">
                <p className="fs-4 text-center fw-bold" style={{ color: "#003939" }}> " डिजिटल मार्केटिंग से ब्रांड की पहचान बढ़ाएं, वेबसाइट और मोबाइल एप्प से मजबूती लाएं। " </p>
              </blockquote>
            </figure>
          </Col>
        </Row>
      </div>
      <div className='d-none d-sm-block p-0' style={{ backgroundColor: "rgb(235,254,243)" }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <figure className="ms-3">
              <blockquote className="blockquote mb-4">
                <p className="ms-2 mt-3 fs-3 text-start fw-bold sm-fs-5" >डिजिटल मार्केटिंग से ब्रांड की पहचान बढ़ाएं, वेबसाइट और  मोबाइल एप्प से मजबूती लाएं। </p>
              </blockquote>
              <div className="ps-4 d-flex">
                <Button size="lg" variant="none" style={{ background: '#0AF86F' }} className="fw-semibold rounded-pill" >Get free consultansy</Button>
                <p className="mt-3 ms-3"><a href="#" className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover  fw-semibold">Get more info</a></p>
              </div>
            </figure>
          </Col>
          <Col className="d-flex justify-content-end col-6">
            <Lottie animationData={colaboration} loop={true} />
            {/* <Image src="https://www.fragosmedia.com/wp-content/uploads/2023/01/Meta-Ads-1.png" alt="Meta Ads" width={"100%"}/> */}
          </Col>
        </Row>
      </div>
      <div className="bg-success-subtle" style={{}} >

        <p className="h4 ms-3 text-dark">Our Services</p>

        <Container fluid >
          <Row xs={1} sm={2} md={3} lg={5} className=" p-1 text-center justify-content-center gx-3">
            {
              serlist.map((item) =>
                <Card className="me-1 mb-1 p-0 shadow-md border border-success-subtle" key={item.id}  >
                  <Card.Img src={item.url} />
                  <Card.Title style={{ color: "#003939" }} className="fw-bold">{item.title}</Card.Title>
                  <Card.Text className="fw-normal text-center fs-6 fw-medium text-break text-dark">{item.des}
                    <p className="text-center"><a href="#" className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover  fw-semibold">Get more info</a></p>
                  </Card.Text>

                </Card>
              )
            }
          </Row>
        </Container>
      </div>
      
      
      <div className="p-2" style={{backgroundImage:"linear-gradient(to right, #051937, #004d7a, #008793, #00bf72, #a8eb12)" }}>
        <Row className=" p-1 text-center justify-content-center gx-3">
          <Col sm lg={6} className="d-flex justify-content-center align-items-center" >
            <p className="fs-1 fw-bold text-light" >Why Choose Us ?</p>
          </Col>
          
          <Col sm className="d-flex flex-column justify-content-end me-3">
            {
              whylist.map((item) =>
                <div className="shadow-sm rounded border border-primary-subtel mb-2 p-3 bg-light" key={item.id}>
                  <p style={{ color: "#003939" }} className="fw-bold text-start"><MdOutlineDone color="green" size={35} className=" me-3"/>{item.title}</p>
                </div>
              )
            }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
