import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Lottie from "lottie-react";
import Done from "../Donefile/Done.json";
import { useNavigate } from 'react-router-dom'

import { ClipLoader } from "react-spinners";

const Client_form: React.FC = () => {
  const [busi_name, setBusiname] = useState<string>("");
  const [owner_name, setOnwer_name] = useState<string>("");
  const [owner_mobile, setOwner_mobile] = useState<string>("");
  const [owner_email, setOwner_email] = useState<string>("");
  const [busi_address, setBusi_address] = useState<string>("");
  const [busi_area, setBusi_area] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [facebook, setFacebook] = useState<string>("");
  const [city, setcity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [youtube, setYoutube] = useState<string>("");
  const [insta, setInsta] = useState<string>("");
  const [linkedin, setLinkedin] = useState<string>("");
  const [contactPerName, setContactPerName] = useState<string>("");
  const [contactPerMobile,setContactPerMobile]=useState<string>("");
  const [contactPerEmail,setContactPerEmail]=useState<string>("");
  const [contact_alt_mobile,setContact_alt_mobile]=useState<string>("");
  const [website_feedback,setWebsite_feedback]=useState<string>("");
  const [digital_marketing_feedback,setDigital_marketing_feedback]=useState<string>("");
  const [colaborator,setColaborator]=useState<string>("");
  const [alert, setAlert] = useState<Boolean>(false);
  const [show, setShow] = useState(false);
  const [errmsg, setErrmsg] = useState(true);
  const [msg, setMsg] = useState<string>("");
  const [msgcolor, setMsgcolor] = useState<string>("");
  const [busi_own_alt_Mobile, setbusi_own_alt_Mobile] = useState<string>("");
  const navigate = useNavigate();
  const [loggedUrl, setLoggedUrl] = useState<string>("https://codingmaster.dev/career/api/client_data.php");



  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShow(true);
    window.scrollTo({ top:0, behavior: "auto" });
    const headersList = {
      Accept: "*",
    };
    const Owner_mobileNumber=owner_mobile+","+busi_own_alt_Mobile;
    const Contact_per_MobileNumber=contactPerMobile+","+contact_alt_mobile;
    const bodyContent = new FormData();
    bodyContent.append("business_name", busi_name);
    bodyContent.append("business_owner",owner_name);
    bodyContent.append("owner_email", owner_email);
    bodyContent.append("onwer_mobile", Owner_mobileNumber);
    bodyContent.append("business_address", busi_address);
    bodyContent.append("business_area", busi_area);
    bodyContent.append("website",website);
    bodyContent.append("facebook",facebook);
    bodyContent.append("insta",insta);
    bodyContent.append("youtube_channel",youtube);
    bodyContent.append("twitter",twitter);
    bodyContent.append("linkedin",linkedin);
    bodyContent.append("contact_person",contactPerName);
    bodyContent.append("contact_mobile", Contact_per_MobileNumber);
    bodyContent.append("contact_email", contactPerEmail);
    bodyContent.append("website_feedback",website_feedback);
    bodyContent.append("feedback_digi_Marketing",digital_marketing_feedback);
    bodyContent.append("colaborator", colaborator);
    bodyContent.append("state",state);
    bodyContent.append("city", city);
 

    try {
      const req = await fetch(
        loggedUrl,
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );
      
      const data = await req.json();
      console.log(data);
      console.log(data, req.status);
      setMsg(data.message);
      setMsgcolor(data.type);
      if (req.status === 200) {
        setAlert(true);
        setShow(false);

        setTimeout(() => {
          navigate('/')
        }, 7000);
      } else {
        setErrmsg(false);
      }
    } catch (error) {
      console.log("Error:", error)
    }
  };
  const sstorageData = async () => {
    const loggedIn = await sessionStorage.getItem("loggedIn");
    if (loggedIn === "1") {
      const data = sessionStorage.getItem('Employee_id');
      const setloggedinurl = "https://codingmaster.dev/career/api/client_data.php" + "?id=" + data;
      setLoggedUrl(setloggedinurl);
    } else{
      navigate('/login');
    }
  }
  useEffect(() => {

    sstorageData();
  }, [])
  return (
    <div>
      <Container fluid className="mt-3 py-3">
        {alert ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center rounded "
            style={{ height: "70vh" }}
          >
            <Lottie animationData={Done} className="h-50 m-0 p-0" />
            <p className="h5 m-0 p-0">
              Your Application has been submitted, successfully
            </p>
            <p className="h6 m-0 p-0">
              Please wait we are redirecting to our home page.
            </p>
          </div>
        ) : (
          <>
            <h3 className="h4 text-center mb-3">
              Client Application
            </h3>
            <Form onSubmit={submitHandler} >
              <div className=" mb-5 bg-body-tertiary">
              <h5 className="h6 mb-1">Basic Details</h5>
                <Card><Row className="m-2">
                 
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Business name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setBusiname(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Business owner name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setOnwer_name(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Owner Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      onChange={(e) => setOwner_mobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Alternate Mobile</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder=""
                      onChange={(e) => setbusi_own_alt_Mobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Onwer Email Address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      onChange={(e) => setOwner_email(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    // controlId="validationCustom01"
                    className="text-start"
                    controlId="formDropdown"
                  >
                    <Form.Label>Business area</Form.Label>
                    `<Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setBusi_area(e.target.value)}
                      className="custom-select"
                    >
                      <option value="education">Select Area</option>
                      <option value="shop & retails">Shop & Retail</option>
                      <option value="agriculture">Agriculture</option>
                      <option value="automotive">Automotive </option>
                      <option value="business support & and supplies">Business Support & Supplies</option>
                      <option value="construction">Construction & Contractors </option>
                      <option value="real state">Real State</option>
                      <option value="entertainment">Entertainment</option>
                      <option value="food & dining">Food & Dining  </option>
                      <option value="health & medicine">Health & Medicine  </option>
                      <option value="legal & financial">Legal & Financial </option>
                      <option value="home & garden">Home & Garden</option>
                      <option value="manufacturing">Manufacturing, Wholesale,
                      Distribution</option>
                      <option value="travel & transport">Travel & Transportation</option>
                      <option value="others">Others</option>
                    </Form.Select>`
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setcity(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Business Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setBusi_address(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Select onChange={(e) => setState(e.target.value)}>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Dadra and Nagar Haveli">
                        Dadra and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Puducherry">Puducherry</option>
                    </Form.Select>
                  </Form.Group>
                </Row></Card>

                <h5 className="h6 my-2">Social Media & Website</h5>
                <Card><Row className="m-2">
                 
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Facebook Page</Form.Label>
                    <Form.Control
                      
                      type="text"
                      placeholder=""
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Instagram Account</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => setInsta(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Twitter </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Youtube Channel</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => setYoutube(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Linkedin Page</Form.Label>
                    <Form.Control
                     
                      type="text"
                      placeholder=""
                      onChange={(e) => setLinkedin(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  
                </Row></Card>

                <h5 className="h6 my-2">Contact Person Detail's</h5>
                <Card><Row className="m-2">
                 
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setContactPerName(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      onChange={(e) => setContactPerMobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Alternate Mobile</Form.Label>
                    <Form.Control
                      
                      type="number"
                      placeholder=""
                      onChange={(e) => setContact_alt_mobile(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      onChange={(e) => setContactPerEmail(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row></Card>

                <h5 className="h6 my-2">FeedBack</h5>
                <Card><Row className="m-2">
                 
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      as="textarea" rows={3} 
                      placeholder=""
                      onChange={(e) => setWebsite_feedback(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Digital Marketing</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      as="textarea" rows={3} 
                      placeholder=""
                      onChange={(e) => setDigital_marketing_feedback(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="validationCustom01"
                    className="text-start"
                  >
                    <Form.Label>Colaborator</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      onChange={(e) => setColaborator(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                 
                </Row></Card>
                <Row className="mb-3">
                  <div className="text-center">
                    <Button variant="primary" className="btn my-3 fw-bold text-dark" type="submit" style={{ backgroundColor: 'rgb(10,250,111)', borderColor: 'rgb(9,228,102)' }}>
                      Submit
                    </Button>
                  </div>
                </Row>
              </div>
            </Form>
          </>
        )}
      </Container>
      <Modal show={show} backdrop={"static"} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>Processing your data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {errmsg ? (
            <>
              <ClipLoader
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
                size={40}
                color="#16348C"
              />
              <p className="h6">Please wait</p>
            </>
          ) : (
            <>
              <p className={`text-${msgcolor}`}>Message:{msg}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Client_form;
