import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, FormLabel } from "react-bootstrap";
import { Front } from "react-bootstrap-icons";
import Progressbarview from "./Progressbarview";
import { ProgressBar,Modal } from "react-bootstrap";
import {useNavigate} from 'react-router-dom'


interface response_data{
  type:string;
  message:string;
}


function Enrollincourses() {
  const [fisrtn, setFirstn] = useState("");
  const [lastn, setLastn] = useState("");
  const [mobile, setMobile] = useState("");
  const [altmobile, setaltMob] = useState("");
  const [email, setEmail] = useState("");
  const [course, setCourse] = useState("");
  const [progress,setProgress]=useState<boolean>()
  const navigate=useNavigate();
  const [address, setAddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setState] = useState("");
  const [res_data, setRes_data] = useState<any>([]);
  const [school, setSchool] = useState("");
  const [education, setEducation] = useState("");
  const [enrolled, setEnrolled] = useState("");
  const [nameschoolorg, setNameSchoolOrg] = useState("");
  const [prog_lang, setProg_lang] = useState("");
  const [promocode, setPromocode] = useState("DIS500");
  const [idProof, setIdProof] = useState<File | undefined>();
  const [show, setShow] = useState(false);
  const [loggedUrl,setLoggedUrl]=useState<string>("https://codingmaster.dev/career/api/enroll_in_course.php");
  const[loggedUser,setLoggedUser]=useState<string>("");
  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement & {
        files: FileList;
    }
    setIdProof(target.files[0]);
};

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.scrollTo({ top:0, behavior: "auto" });
   
    try {
      const headersList = {
        Accept: "*/*",
      };
      if (typeof idProof === 'undefined') return;
      const Enroll_data=enrolled+prog_lang;
      const Academic_course=course+school;
      const Fullname=fisrtn+lastn
      const bodyContent = new FormData();
      bodyContent.append("name", Fullname);
      bodyContent.append("email", email);
      bodyContent.append("mobile", mobile);
      bodyContent.append("course", course);
      bodyContent.append("address", address);
      bodyContent.append("al_mobile", altmobile);
      bodyContent.append("city", city);
      bodyContent.append("state", state);
      bodyContent.append("enrolled_in", Enroll_data);
      bodyContent.append("education", education);
      bodyContent.append("name_edu_org",nameschoolorg);
      bodyContent.append("academic_course",Academic_course);
      bodyContent.append("promocode",promocode);
      bodyContent.append("id_proof", idProof);

      console.log(Fullname,mobile,email,course,address,altmobile,city,state,Enroll_data,education,nameschoolorg,Academic_course,promocode,idProof);
      setProgress(true);
      
      const response = await fetch(
        loggedUrl,
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );

      const data = await response.json();
      console.log(data);
      setRes_data(data as response_data[]);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if (response.status === 200) {
        
        setProgress(false);
        handleShow();
        setTimeout(() => {
          handleClose();
          navigate('/');
        }, 5000);
        
      }
      console.log("Form data submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sstorageData=async()=>{
    const loggedIn=await sessionStorage.getItem("loggedIn");
    if (loggedIn==="1") {
      const data= sessionStorage.getItem('Employee_id');
      const setloggedinurl="https://codingmaster.dev/career/api/enroll_in_course.php"+"?id="+data;
      setLoggedUrl(setloggedinurl);
    }
  }
  useEffect(()=>{
   sstorageData();
  },[])
  return (
    <>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className={`text-${res_data.type}`}>{res_data.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-${res_data.type}`}>{res_data.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    {progress ? <ProgressBar animated={true} now={100} role='progress' className='w-100' style={{ height: '5px', marginTop: '1px' }} /> : null}
    <div className="container-fluid ">
     
      <div className="container mb-3 ">
        <h4 className="text-center">Enroll In Courses</h4>
      </div>
      <form className="row g-4" onSubmit={submitHandler}>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            id="validationDefault01"
            required
            placeholder="First name"
            value={fisrtn}
            onChange={(e) => setFirstn(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            id="validationDefault02"
            required
            placeholder="Last Name"
            onChange={(e) => setLastn(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
              placeholder=" Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
              value={altmobile}
              placeholder=" Alternate Mobile Number"
              onChange={(e) => setaltMob(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-4">
          <input
            type="email"
            className="form-control"
            id="validationDefault03"
            required
            placeholder="  E-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="col-md-4">
          <select
            className="form-select form-select-indicator-color:warning"
            id="validationDefault04"
            required
            value={education}
            onChange={(e) => {
              setEducation(e.target.value);
              // console.log(course);
            }}
          >
            <option selected disabled value="">
              Education
            </option>
            <option className="custom-select" value={"school"}>
              School{" "}
            </option>
            <option className="custom-select" value={"college"}>
              College
            </option>
            <option className="custom-select" value={"institute"}>
              Institute{" "}
            </option>
            <option className="custom-select" value={"university"}>
              University
            </option>
            <option className="custom-select" value={"other"}>
              Other..
            </option>
          </select>
        </div>

        {education === "school" ? (
          <div className="col-md-4">
            <select
              className="form-select"
              id="validationDefault04"
              required
              value={school}
              onChange={(e) => setSchool(e.target.value)}
            >
              <option selected value="">
                classes....
              </option>
              <option value={"8th"}>8th </option>
              <option value={"9th"}>9th</option>
              <option value={"10th"}>10th</option>
              <option value={"11th"}>11th</option>
              <option value={"12th"}>12th</option>
            </select>
          </div>
        ) : (
          <div className="col-md-4">
            <select
              className="form-select"
              id="validationDefault04"
              required
              value={course}
              onChange={(e) => setCourse(e.target.value)}
            >
              <option selected value="">
                Courses...
              </option>
              <option>BSC </option>
              <option>B.COM</option>
              <option>BCA </option>
              <option>BA</option>
              <option>Other...</option>
            </select>
          </div>
        )}

        <div className="col-md-4">
          <select
            className="form-select"
            id="validationDefault04"
            required
            value={enrolled}
            onChange={(e) => {
              setEnrolled(e.target.value);
            }}
          >
            <option selected value="">
              Enrolled In
            </option>
            <option value={"webdevelopment"}>Web Development</option>
            <option value={"appdevelopment"}> Mobile App Development</option>
            <option value={"robotics/iot"}> Robotics/Iot</option>
            <option value={"digitalmarketing "}>Digital Marketing </option>
            <option value={" graphicsdesigning"}> Graphics Designing</option>
            <option value={"programinglanguage"}>Programing Languagae</option>
          </select>
        </div>

        {enrolled === "programinglanguage" ?<div className="col-md-4  ">
          <div className="row mw-100 m-auto">
          <select
            className="form-select"
            id="validationDefault04"
            required
            value={prog_lang}
            onChange={(e) => {
              setProg_lang(e.target.value);
            }}
          >
            <option selected value="">
              Language
            </option>
            <option value={"C/C++"}>C/C++</option>
            <option value={"Java"}>Java</option>
            <option value={"Python"}>Python</option>
            
          </select>
          </div>
        </div>:null}

        <div className="col-md-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
              value={nameschoolorg}
              placeholder="Name of School/College/Institutes/University "
              onChange={(e) => setNameSchoolOrg(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            id="validationDefault03"
            required
            placeholder="City"
            onChange={(e) => setcity(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <select
            className="form-select"
            id="validationDefault04"
            required
            onChange={(e) => setState(e.target.value)}
          >
            <option selected disabled value="">
              State...
            </option>
            <option> Andhra Pradesh</option>
            <option> Arunachal Pradesh</option>
            <option> Assam</option>
            <option> Chhattisgarh</option>
            <option> Goa</option>
            <option> Gujarat</option>
            <option> Haryana</option>
            <option> Himachal Pradesh</option>
            <option> Jammu and Kashmir</option>
            <option> Jharkhand</option>
            <option> Karnataka</option>
            <option> Kerala</option>
            <option> Madhya Pradesh</option>
            <option> Maharashtra</option>
            <option> Manipur</option>
            <option> Meghalaya</option>
            <option> Mizoram</option>
            <option> Nagaland</option>
            <option> Orissa</option>
            <option> Punjab</option>
            <option> Rajasthan</option>
            <option> Sikkim</option>
            <option> Tamil Nadu</option>
            <option> Tripura</option>
            <option> Uttarakhand </option>
            <option> Uttar Pradesh</option>
            <option> West Bengal</option>
            <option> Chandigarh</option>
            <option> Delhi</option>
            <option> Lakshadweep</option>
            <option> Andhra Pradesh</option>
            <option> Andhra Pradesh</option>
          </select>
        </div>

        <div className="col-12">
          <FormGroup>
            <Form.Label>Id Proof(Aadhar/VoterID)</Form.Label>
            <Form.Control
              type="file"
              required
              placeholder="Enter id profe"
              onChange={onFileInputChange}
            ></Form.Control>
          </FormGroup>
        </div>

        {/* <div className="mb-3 text-start">
            <label form="formFile" className="form-label">
              Select Resume{" "}
            </label>
            <input className="form-control" type="file" id="formFile" 
             onChange={(e)=>setSelect(e.target.value)}/>
          </div> */}
          <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            id="validationDefault03"
            required
            defaultValue={promocode}
            placeholder={promocode}
            onChange={(e) => setPromocode(e.target.value)}
          />
        </div>
        <div className="col-12 text-center">
          <Button className="btn mb-3 fw-bold text-dark" type="submit" style={{backgroundColor:'rgb(10,250,111)',borderColor:'rgb(9,228,102)'}}>
            Submit form
          </Button>
        </div>
      </form>
    </div>
    </>
  );
}
export default Enrollincourses;
