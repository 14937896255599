import React from "react";

const Job = () => {
  return (
    <div
      className="container-fluid   "
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className="row bg-success "
        style={{ width: "100vw", height: "70vh " }}
      >
        <div
          className="  col-4 h-100  col-md-4 col-sm-4 "
          style={{ backgroundColor: "hsl(38, 91%, 58%)" }}
        >
          <div className="row">
            <div className="col p-5 \">
              <h1>Find your career.</h1>
              <h1 className="pb-3">You Deserve it .</h1>
              <p>
                Codding Master is an IT company specializing in software
                development and technology solutions. They offer a range of
                services, including web and mobile app development, tailored to
                meet the needs of various businesses.
              </p>
            </div>
          </div>
        </div>
        <div className="col-8 h-100  w-[60%] col-md-8 col-sm-8">
          <img
            className=" h-100  object-fit-cover m-auto w-100 bg-primary col-md-8 col-sm-8 "
            src="https://img.freepik.com/free-photo/portrait-pensive-young-african-man-eyeglasses_171337-7783.jpg?t=st=1720169268~exp=1720172868~hmac=bba854860df9d9b8d73e43cb4cc690ef9d4e7a9614434e1a27cf12eb65149d34&w=996"
            alt=""
          />
        </div>
      </div>
      <div
        className="row  container m-auto  flex p-2 mt-5 gap-1"
        style={{ height: "70vh" }}
      >
        <div className="row w-full h-50 bg-success m-auto ">
          <div className="col-xl-8  col-md-8"></div>
          <div className="col-xl-4 col-md-4"></div>
        </div>
        <div className="row w-full h-50 bg-success m-auto">
          <div className="col-xl-4 col-md-4 "></div>
          <div className="col-xl-8  col-md-8 "></div>
        </div>
      </div>
    </div>
  );
};

export default Job;
