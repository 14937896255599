import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import moduleName, { CloseButton, Dropdown, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import {
  Envelope,
  Facebook,
  Instagram,
  TwitterX,
  Whatsapp,
  X,
  XCircle,
  XCircleFill,
  Youtube,
} from "react-bootstrap-icons";
import { FaFacebookSquare } from "react-icons/fa";
import {
  FaYoutube,
  FaSquareInstagram,
  FaSquareXTwitter,
  FaSquareWhatsapp,
} from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { userInfo, UserInfo } from "os";

interface User_data {
  aadhar: string;
  address: string;
  department: string;
  district: string;
  email: string;
  emp_id: string;
  emp_type: string;
  id: string;
  mobile: string;
  name: string;
  pan: string;
  password: string;
  picture: string;
  state: string;
}

const NavBar: React.FC = () => {
  const [show, setShow] = useState<Boolean>(false);
  const [adminData, setAdminData] = useState<any>({});
  const [Alert_notifation, setAlert_Notification] = useState<any>(true);
  const [logintxt, setLogintxt] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [user_info, setUser_info] = useState<any>([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleHomeClick = () => {
    handleClose();
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    } else {
      window.location.reload();
    }
  };
  const handlelogoutModal = () => {
    setLogoutModal(true);
    // if (condition) {
    // window.sessionStorage.clear();
    // setLogoutModal(false);
    // navigate('/login');
    // window.location.reload();
    // }
  };
  const logoutUser = () => {
    window.sessionStorage.clear();
    setLogoutModal(false);
    navigate("/");
    window.location.reload();
  };
  const closeModal = () => {
    setLogoutModal(false);
  };
  const clickOnRegister_now = () => {
    window.scrollTo({ top: 650, behavior: "auto" });
  };
  useEffect(() => {
    const loggedIn_data = window.sessionStorage.getItem("loggedIn");
    const data = window.sessionStorage.getItem("userinfo");
    const parseData = data !== null ? JSON.parse(data) : null;
    setUser_info(parseData as User_data[]);

    if (loggedIn_data === "1") {
      setLogintxt(true);
      window.sessionStorage.setItem("Employee_id", parseData.emp_id);
    } else {
      setLogintxt(false);
    }
  }, []);

  return (
    <>
      
      <Modal show={logoutModal} onHide={handlelogoutModal}>
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do, you want to Logout</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={logoutUser}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {["sm"].map((expand) => (
        <Navbar expand={expand} sticky={"top"} className="mb-0 p-0" bg="light" data-bs-theme="light">
          <Container fluid className="m-0 shadow-sm rounded" >
            <Image
              height={60}
              src={`https://codingmaster.dev/career/api/logo_icon_codingmaster.png`}
              roundedCircle
            />
            <Navbar.Brand
              href="#"
              className="ms-2 fs-3 fw-bold 
            " style={{ color: "rgb(23,26,31)" }}
            >
              Coding Master
            </Navbar.Brand>
            <Navbar.Toggle
              className="shadow-none border-0"
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={handleShow}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{ color: "rgb(23,26,31)" }}>
                  <Image
                    height={45}
                    src={`https://codingmaster.dev/career/api/logo_icon_codingmaster.png`}
                    roundedCircle
                    className="me-2 fw-bold "

                  />
                  Coding Master
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3 gap-2 ">
                  <Nav.Link
                    onClick={handleHomeClick}
                    className=" text-start text-black fw-5 mt-1 md:mt-0 fs-5 " style={{ color: "rgb(86,93,109)" }}
                  >
                    Home
                  </Nav.Link>

                  <Dropdown className="text-start fw-5 md:mt-0  ">
                    <Dropdown.Toggle variant="light" id="dropdown-basic" className="fw-5 mt-1 md:mt-0 fs-5">
                      Services
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Website Development</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Mobile App Development</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Digital Marketing</Dropdown.Item>
                      <Dropdown.Item href="/career">Career</Dropdown.Item>
                      <Dropdown.Item href="/career">Apply for Internships</Dropdown.Item>
                      <Dropdown.Divider/> 

                    </Dropdown.Menu>
                  </Dropdown>
                  

                  <Nav.Link
                    as={Link}
                    onClick={handleClose}
                    to={"/about"}
                    className=" text-start text-black font-weight-bold mt-1 md:mt-0 fs-5 " style={{ color: "rgb(86,93,109)" }}
                  >
                    About
                  </Nav.Link>

                
                  {/* dropdown end */}
                  {logintxt ? (
                    <>
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={user_info.name}
                        menuVariant="dark"
                        className="fw-bold mt-1"
                        style={{ color: "rgb(86,93,109)" }}
                      >
                        <NavDropdown.Item
                          href="#action/3.1"
                          as={Link}
                          to={"/team-dash"}
                          onClick={handleClose}
                          style={{ color: "rgb(86,93,109)" }}
                        >
                          Dashbord
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="#action/3.2"
                          onClick={() => {
                            handleClose();
                            handlelogoutModal();
                          }}
                          style={{ color: "rgb(86,93,109)" }}
                        >
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>

                     
                    </>
                  ) : (
                    <Nav.Link
                      as={Link}
                      onClick={handleClose}
                      to={"/login"}
                      className=" text-start text-black font-weight-bold mt-1 md:mt-0 fs-5" style={{ color: "rgb(86,93,109)" }}
                    >
                      login
                    </Nav.Link>

                  )}
                  <Nav.Link
                    as={Link}
                    onClick={handleClose}
                    to={"/Contact"}

                    className="text-start mt-1 md:mt-0 fs-5 " style={{ color: "rgb(86,93,109)" }}
                  >
                    Contact us
                  </Nav.Link>
                </Nav>
                <Nav className="justify-content-end flex-grow-1 pe-3 mt-2">
                  <div className="d-block d-sm-none">
                    <hr />
                    <p className="fw-semibold">Follow & Subscribe</p>
                  </div>
                  <div className="d-flex flex-sm-row flex-column justify-content-between">
                    <div>
                      <p className="m-2 fs-5" style={{ color: "rgb(86,93,109)" }} >+91 9171959701</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-start ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100067122636154"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookSquare
                          className="fs-2 mx-2  about-icon"
                          style={{ color: "rgb(8, 102, 255)" }}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/codingmaster.dev?igsh=NWcyNHU5NGttZGM1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaSquareInstagram
                          className="fs-2 mx-2  about-icon"
                          style={{ color: "#DC30E3" }}
                        />
                      </a>
                      
                      <a
                        href="https://www.youtube.com/@coding_maste-rdev"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube
                          className="fs-2 mx-2 about-icon"
                          style={{ color: "red" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="d-block d-sm-none">
                    <hr />
                    <p>Contact & Email</p>
                    <div className="d-flex flex-sm-row flex-column">
                      <div className="d-flex justify-content-start p-0">
                        <FaSquareWhatsapp className="fs-2" color="limegreen" />
                        <p className="ms-2 fw-semibold">+91 9171959701</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-sm-none">
                    <div className="d-flex flex-sm-row flex-column">
                      <div className="d-flex justify-content-start p-0">
                        <a
                          href="mailto:contact@codingmaster.dev"
                          className="link-offset-2 link-underline link-underline-opacity-0 d-flex"
                        >
                          <IoMail className="fs-2" color="limegreen" />
                          <p className="ms-2 fw-semibold">
                            contact@codingmaster.dev
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};
export default NavBar;
