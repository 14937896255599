import React, { useState } from "react";

const Form1: React.FC = () => {
  const [fisrtn, setFirstn] = useState("");
  const [lastn, setLastn] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [course, setCourse] = useState("");
  const [project, setProject] = useState("");
  const [experience, setExperience] = useState("");
  const [address, setAddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setState] = useState("");
  const [select, setSelect] = useState("");


  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formdata = { fisrtn, lastn, mobile, email, course, project, experience, address, city, state, select }
    // console.log(formdata);
    try {
      const headersList = {
        "Accept": "*/*",
      }
      const bodyContent = new FormData();
      bodyContent.append("fname", fisrtn);
      bodyContent.append("lname", lastn);
      bodyContent.append("email", mobile);
      bodyContent.append("mobile", email);
      bodyContent.append("projectlink", project);
      bodyContent.append("course", course);
      bodyContent.append("address", address);
      bodyContent.append("experience", experience);
      bodyContent.append("city", city);
      bodyContent.append("state", state);
      bodyContent.append("resume", select);

      const response = await fetch("https://e-krishak.in/career/api/submitform.php", {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (response.status === 200) {
        alert("Successfully");
      }
      console.log('Form data submitted successfully:', data);
    } catch (error) {
      console.error('Error submitting form data:', error);
    }

  }
  return (
    <>
      <div className="container card mb-3 mt-5 p-3 w-75">
        <div className="container mb-3 ">
          <h4>React Native Developer Intern's / Fresher Hiring</h4>
        </div>
        <form className="row g-4" onSubmit={submitHandler}>
          <div className="col-md-4 ">
            <input
              type="text"
              className="form-control"
              id="validationDefault01"
              required
              placeholder="First name"
              value={fisrtn}
              onChange={(e) => setFirstn(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              id="validationDefault02"
              required
              placeholder="Last Name"
              onChange={(e) => setLastn(e.target.value)}
            />
          </div>
          <div className="col-md-4 ">
            <div className="input-group">
              <input
                type="phone"
                className="form-control"
                id="validationDefaultUsername"
                aria-describedby="inputGroupPrepend2"
                required
                placeholder=" Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="validationDefault03"
              required
              placeholder="  E-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <select className="form-select" id="validationDefault04" required
              value={course}
              onChange={(e) => setCourse(e.target.value)}>
              <option selected disabled value="">
                Course..
              </option>
              <option>B.TECH </option>
              <option>BCA</option>
              <option>BSC IT </option>
              <option>MCA</option>
              <option>Other..</option>
            </select>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              id="validationDefault02"
              required
              placeholder=" Project Link "
              onChange={(e) => setProject(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="number"
              className="form-control"
              id="validationDefault02"
              required
              placeholder="Experience (month's)"
              onChange={(e) => setExperience(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="validationDefaultUsername"
                aria-describedby="inputGroupPrepend2"
                required
                placeholder="Address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="validationDefault03"
              required
              placeholder="City"
              onChange={(e) => setcity(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <select className="form-select" id="validationDefault04" required
              onChange={(e) => setState(e.target.value)}>
              <option selected disabled value="">
                State...
              </option>
              <option> Andhra Pradesh</option>
              <option> Arunachal Pradesh</option>
              <option> Assam</option>
              <option> Chhattisgarh</option>
              <option> Goa</option>
              <option> Gujarat</option>
              <option> Haryana</option>
              <option> Himachal Pradesh</option>
              <option> Jammu and Kashmir</option>
              <option> Jharkhand</option>
              <option> Karnataka</option>
              <option> Kerala</option>
              <option> Madhya Pradesh</option>
              <option> Maharashtra</option>
              <option> Manipur</option>
              <option> Meghalaya</option>
              <option> Mizoram</option>
              <option> Nagaland</option>
              <option> Orissa</option>
              <option> Punjab</option>
              <option> Rajasthan</option>
              <option> Sikkim</option>
              <option> Tamil Nadu</option>
              <option> Tripura</option>
              <option> Uttarakhand </option>
              <option> Uttar Pradesh</option>
              <option> West Bengal</option>
              <option> Chandigarh</option>
              <option> Delhi</option>
              <option> Lakshadweep</option>
              <option> Andhra Pradesh</option>
              <option> Andhra Pradesh</option>
            </select>
          </div>
          {/* <div className="mb-3 text-start">
            <label form="formFile" className="form-label">
              Select Resume{" "}
            </label>
            <input className="form-control" type="file" id="formFile" 
             onChange={(e)=>setSelect(e.target.value)}/>
          </div> */}
          <div className="col-12">
            <button className="btn btn-primary mb-3" type="submit">
              Submit form
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form1;
