import React, { useState } from "react";
import "remixicon/fonts/remixicon.css";
import custom from "../Custom.module.css"

const Contect_Us = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setModalOpen(true);
    setName("");
    setMobile("");
    setEmail("");
    setMessage("");
    setCity("");
    setState("");
    setCompany("");
    console.log("hello!");
  };
  const [modalopen, setModalOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  console.log(name, mobile, email, company, state, city);

  return (
    <>
      <div className="container-fluid" style={{ height: "250vh" }}>
        <div className="h-100 w-100 ">
          <div className="w-100 " style={{ height: "50vh" }}>
            <div className="w-100 h-100 ">
              <div className="row h-100 w-100">
                <div className="col-12 h-100 w-100 object-fit-cover">
                  <img
                    src="https://img.freepik.com/premium-photo/concept-communication-contact-us-customer-support-hotline-people-connect-business-hand-holding-virtual-screen-contact-icons-email-address-live-chat-with-internet-wifi_1165532-48438.jpg?w=1380"
                    alt=""
                    className="h-100 w-100 img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 " style={{ height: "130vh" }}>
            <div className="container w-100 h-100  mt-5 ">
              <div className="row bg-success  p-5 mb-5">
                <div className="col-12  text-uppercase d-flex align-items-center justify-content-center bg-success  "></div>
                <h1 className="fs-1 fw-bold text-light text-center">
                  We listen and we are here to help!
                </h1>
              </div>
              <div className="row w-100  m-auto " style={{ height: "99vh" }}>
                <div className=" col-md-4">
                  <div className="row h-100 ">
                    <div className="col-12  border border-3 mb-4">
                      <div
                        className="row h-100 w-100 m-auto 
                      "
                      >
                        <div className="col-2 h-100 d-flex align-items-center justify-content-center">
                          <i
                            className="ri-map-pin-fill fs-1 text-primary"
                            // style={{ color: "#xEF13" }}
                          ></i>
                        </div>
                        <div className="col-8 h-100 d-flex align-items-center justify-content-center ">
                          <div className="row h-50 w-100 ">
                            <div className="col-12 ">
                              <h5 className="fs-4  fw-bold">Office Address</h5>
                            </div>
                            <div className="col-12 ">
                              <p className=" fw-medium">
                                Khutehi APSU Road Rewa 486001
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 border border-3 mb-4 ">
                      <div
                        className="row h-100 w-100 m-auto 
                      "
                      >
                        <div className="col-2 h-100 d-flex align-items-center justify-content-center ">
                          {/* <i
                            className=""
                            // style={{ color: "#xEF13" }}
                          ></i> */}
                          <i className="ri-phone-fill ri-map-pin-fill fs-1 text-primary"></i>
                        </div>
                        <div className="col-8 h-100 d-flex align-items-center justify-content-center">
                          <div className="row h-50 w-100">
                            <div className="col-12 ">
                              <h5 className="fs-4 fw-bold">Contact Number</h5>
                            </div>
                            <div className="col-12 ">
                              <p className=" fw-medium">+91 9171959701</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 border border-3 mb-4">
                      <div
                        className="row h-100 w-100 m-auto 
                      "
                      >
                        <div className="col-2 h-100 d-flex align-items-center justify-content-center ">
                          {/* <i
                            className="ri-map-pin-fill "
                            // style={{ color: "#xEF13" }}
                          ></i> */}

                          <i className="ri-mail-open-fill fs-1 text-primary"></i>
                        </div>
                        <div className="col-8 h-100 d-flex align-items-center justify-content-center">
                          <div className="row h-50 w-100">
                            <div className="col-12 ">
                              <h5 className="fs-4 fw-bold">Email</h5>
                            </div>
                            <div className="col-12 ">
                              <a href="" className="fw-medium">
                                contact@codingmaster.dev
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 border border-3  ">
                      <div
                        className="row h-100 w-100 m-auto 
                      "
                      >
                        <div className="col-2 h-100 d-flex align-items-center justify-content-center ">
                          {/* <i
                            className="ri-map-pin-fill "
                            // style={{ color: "#xEF13" }}
                          ></i> */}
                          <i className="ri-earth-fill fs-1 text-primary"></i>
                        </div>
                        <div className="col-8 h-100 d-flex align-items-center justify-content-center">
                          <div className="row h-50 w-100">
                            <div className="col-12 ">
                              <h5 className="fs-4 fw-bold">Website</h5>
                            </div>
                            <div className="col-12 ">
                              <p className=" fw-medium">www.codingmaster.dev</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-md-8">
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="row h-100">
                    <div className="row h-100  m-auto ">
                      <div className="container mt-5">
                        <h2>Contact Us</h2>
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Enter your name"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                              Mobile
                            </label>
                            <input
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                              type="tel"
                              className="form-control"
                              id="mobile"
                              placeholder="Enter your mobile number"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Enter your email"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="company" className="form-label">
                              Company
                            </label>
                            <input
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              type="text"
                              className="form-control"
                              id="company"
                              placeholder="Enter your company name"
                            />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                            <input
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder="Enter your city"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <div className="form-group">
                              <label>State</label>
                              <select
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                className="form-control"
                                id="state"
                                required
                              >
                                <option value="">State</option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                            </div>
                          </div>

                          {/* <div className="mb-3">
                              <label htmlFor="country" className="form-label">
                                Country
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="country"
                                placeholder="Enter your country"
                              />
                            </div> */}

                          <div className="mb-3">
                            <label htmlFor="message" className="form-label">
                              Message
                            </label>
                            <textarea
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              className="form-control"
                              id="message"
                              // rows="3"
                              placeholder="Enter your message"
                            ></textarea>
                          </div>

                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </form>
                      </div>
                      {modalopen && (
                        <div className={custom.modal}>
                          <div className="modal-content">
                            <h2>फॉर्म सबमिट हो गया!</h2>
                            <button onClick={() => setModalOpen(false)}>
                              बंद करें
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contect_Us;
