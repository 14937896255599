import { useLocation } from "react-router-dom";
import Allstyle from "../Custom.module.css";
import Digital from "./Digital";
import { useEffect, useRef } from "react";

const Services = () => {
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    if (location.state?.scrollToTop) {
      topSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const topSectionRef = useRef<HTMLDivElement>(null);
  console.log(topSectionRef);
  const cardsData = [
    {
      image:
        "https://img.freepik.com/free-vector/creative-graphic-design-lettering_23-2148470665.jpg?t=st=1723268968~exp=1723272568~hmac=09f88c67b69f2bc2d305e8d867f6a05f7c808f3669b3f73f8603c6f3d769599b&w=996",
      title1: "Design",
      title2: "Graphic Design",
      text: "Web Design.",
    },
    {
      image:
        "https://img.freepik.com/free-photo/digital-marketing-with-icons-business-people_53876-94833.jpg?t=st=1723269048~exp=1723272648~hmac=a5b431401a7b6d6e6b0447373d5030837cf331f0fd6d84d2df7f7032149ce9dc&w=900",
      title1: "Marketing",
      title2: "Digital Branding",
      text: "Digital Marketing.",
    },
    {
      image:
        "https://img.freepik.com/free-vector/new-app-development-desktop_23-2148684987.jpg?t=st=1723269724~exp=1723273324~hmac=4c3138c37655ef054222360aeb7e74690c19663416c557091df4ae91eb9299f9&w=740",
      title1: "Development",
      title2: "Web Development",
      text: "App Development.",
    },
    {
      image:
        "https://img.freepik.com/free-vector/illustrated-woman-being-intern-company_23-2148726151.jpg?t=st=1723269999~exp=1723273599~hmac=6875694d7d5cc51af1b56aeca47b34b043e1b2a3deb1e458a20b4b7c3150bedc&w=740",
      title1: "Training",
      title2: "Industrial Training",
      text: "Corporate Training.",
    },
    {
      image:
        "https://img.freepik.com/free-vector/consulting-concept-illustration_114360-2579.jpg?t=st=1723270077~exp=1723273677~hmac=f2209e8abad3658c2235d84ecb3e3a44580364c440856ab353c9eec643250d54&w=740",
      title1: "Consulting",
      title2: "Digital Consulting",
      text: "Business Consulting.",
    },
    {
      image:
        "https://img.freepik.com/free-vector/customer-support-flat-design-illustration_23-2148889374.jpg?t=st=1723270211~exp=1723273811~hmac=42a27b6e47c8a4a54b68ccb4d20baadbd4fc58c199573984339f77e7533db370&w=740",
      title1: "Support",
      title2: "On Demand Support",
      text: "Continuous Support.",
    },
    {
      image:
        "https://img.freepik.com/free-vector/www-concept-illustration_114360-2143.jpg?t=st=1723270292~exp=1723273892~hmac=988eb517a2063488ed1451d30d0313f4203c8cd68374f873536da0eb32584fc2&w=740",
      title1: "Web",
      title2: "Web Hosting",
      text: "Web Communication.",
    },
  ];
  return (
    <>
      <div
        ref={topSectionRef}
        className="container-fluid bg-light"
        style={{
          background: "rgb(191, 201, 196) ",
          height: "250vh",
        }}
      >
        <div className="h-100 w-100">
          <div className="w-100" style={{ height: "50vh" }}>
            <div className="container-fluid h-100 w-100">
              <div className="row w-100 h-100">
                <div className="col-12 h-100 w-100 position-relative">
                  <img
                    src="https://img.freepik.com/free-vector/digital-marketing-concept-with-online-advertising-media-symbols-flat_1284-31958.jpg?t=st=1723011244~exp=1723014844~hmac=fb55cb35ea037bec77ff9189d768da6a54a932f5be21493ef03623c82cfa4471&w=1380"
                    alt=""
                    className="h-100 w-100 img-fluid"
                  />
                  <div className="position-absolute h-100 w-100 top-0 ">
                    <div className={Allstyle.glass2}>
                      <div className="row h-100">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                          <h1
                            className="fw-bold"
                            style={{
                              fontSize: "4rem",
                              color: "rgb(2, 152, 71)",
                              textShadow: "2px 2px black",
                            }}
                          >
                            Services
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card Section */}
          <div
            className="container  w-100"
            style={{
              background: "rgb(35, 76, 59)",
              paddingTop: "2rem",
              paddingBottom: "2rem",
              height: "100vh",
              marginTop: "4rem",
            }}
          >
            <div className="container h-100 w-100 overflow-auto">
              <div className="row mb-4">
                <div className="col-12 text-center text-uppercase">
                  <h1 className="fs-1 fw-bold text-light">
                    Performance Driven Digital Services!
                  </h1>
                </div>
              </div>
              <div className="row  d-flex justify-content-center align-content-center m-4  s">
                {cardsData.map((card, index) => (
                  <div
                    key={index}
                    className={`col-sm-12 col-md-6 col-lg-4 mb-4 `}
                  >
                    <div
                      className={`card  ${Allstyle.cardhover} ${Allstyle.cardhover1}`}
                    >
                      <div className="card" style={{ width: "100%" }}>
                        <img
                          src={card.image}
                          className="card-img-top"
                          alt="Card Image"
                          style={{
                            height: " 150px",
                            width: " 150px",
                            objectFit: "cover",
                            margin: "auto",
                            borderRadius: "50%",
                            marginTop: "10px",
                          }}
                        />
                        <div className="card-body d-flex flex-column justify-content-center">
                          <h5 className="card-title fs-2 fw-bold">
                            {card.title1}
                          </h5>
                          <div className="fs-6 fw-semibold">
                            <h6>
                              <span>. </span>
                              {card.title2}
                            </h6>
                            <h6>
                              <span>. </span>
                              {card.text}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" w-100 " style={{ height: "80vh" }}>
            <div className="h-50 bg-black mt-5 d-flex align-items-center justify-content-center">
              <Digital />
            </div>
          </div>
          {/* Card Section End */}
        </div>
      </div>
    </>
  );
};

export default Services;
