import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function About() {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <Card.Title as="h1" className="text-center">About Us - Coding Master</Card.Title>
              <Card.Text className="mt-4">
                Welcome to Coding Master, your premier destination for cutting-edge IT services and comprehensive online training. We specialize in delivering top-notch solutions in website development, mobile app development, and digital marketing, including impactful social media ads.
              </Card.Text>
              <Card.Title as="h2" className="mt-4">Our Services:</Card.Title>
              <ul>
                <li><strong>Website Development</strong>: Crafting visually stunning and highly functional websites tailored to your business needs.</li>
                <li><strong>Mobile App Development</strong>: Creating user-friendly mobile applications that offer seamless experiences across all devices.</li>
                <li><strong>Digital Marketing</strong>: Enhancing your online presence with strategic digital marketing campaigns and targeted social media ads.</li>
              </ul>
              <Card.Title as="h2" className="mt-4">Training and Internships:</Card.Title>
              <Card.Text>
                At Coding Master, we are committed to empowering the next generation of tech professionals. Our online training programs and internships provide hands-on experience and industry insights, ensuring you are well-equipped to thrive in the ever-evolving tech landscape.
              </Card.Text>
              <Card.Text className="mt-4">
                Join us at Coding Master, where innovation meets excellence. Let’s build the future together.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
    
export default About
